
var env = process.env.NODE_ENV || "development"

var config = {
  staging: require("./config/staging.js"),
  production: require("./config/production.js"),
  development: require("./config/development.js")
}
console.log('#################################')
console.log(env)
console.log('#################################')

module.exports = config[env]