import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue } from "bootstrap-vue"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

Vue.config.productionTip = false
Vue.use(BootstrapVue)

import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader';

import { Amplify } from 'aws-amplify'

Amplify.configure({  Auth: {
    region: "ap-northeast-1",
    userPoolId: "ap-northeast-1_HJxm6iFFi",
    userPoolWebClientId: "7f43tq29mfab694ku64f1ml6dj",
    oauth: {
      domain: "adfs-honda.auth.ap-northeast-1.amazoncognito.com",
      scope: ["email", "openid"],
      redirectSignIn: "https://honda23.dilabs21.com/",
      redirectSignOut: "https://honda23.dilabs21.com/",
      responseType: "token"
    }
  },
})

Vue.config.productionTip = false

applyPolyfills().then(() => {
  defineCustomElements(window);
});

Vue.config.ignoredElements = [/amplify-\w*/];

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
